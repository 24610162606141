<!--
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-22 16:08:08
 * @LastEditors: PSG
 * @LastEditTime: 2021-03-22 17:01:43
-->
<template>
  <div v-for="(item, index) in data.choices">
    <a-row>
      <a-switch size="small" v-model:checked="item.ifAllowFillInBlanks" />
    </a-row>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
export default {
  setup() {
    const data = reactive({
      "choices": [
        {
            "ifAllowFillInBlanks": false, //是否能填空
        },
        {
            "ifAllowFillInBlanks": true,
        }
      ]
    })
    return { data }
  },
}
</script>